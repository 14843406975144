import { Quaternion } from "potree/mathtypes";

export const DEG2RAD = Math.PI / 180;
export const RAD2DEG = 180 / Math.PI;

export function generateUUID() {
  // Only available in "secure context" but this is no issue for us.
  const uuid = crypto.randomUUID();
  return uuid;
}

export function clamp(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(max, value));
}

// compute euclidian modulo of m % n
// https://en.wikipedia.org/wiki/Modulo_operation
export function euclideanModulo(n: number, m: number): number {
  return ((n % m) + m) % m;
}

// https://en.wikipedia.org/wiki/Linear_interpolation
export function lerp(x: number, y: number, t: number): number {
  return (1 - t) * x + t * y;
}

export function degToRad(degrees: number): number {
  return degrees * DEG2RAD;
}

export function radToDeg(radians: number): number {
  return radians * RAD2DEG;
}

export function arrayMax(array: number[]): number {
  if (array.length === 0) return -Infinity;

  let max = array.reduce((prev, current) => Math.max(prev, current));

  return max;
}
