export const TRANSLATIONS = {
  EN: {
    MENU: {
      TOOLTIP: {
        directory: "Scenes",
        tools: "Tools",
        filters: "Filters",
        display: "Display Settings",
        feedback: "File Feedback",
        log_out: "Log out",
      },
      DIRECTORY: {
        Scenes: "Scenes",
        Project: {
          NoProjectLoaded: "Project-No",
          NoProjectLoadedSubHeader: "No project data loaded.",
          Demo: "Demo",
        },
        FileDate: "File Date",
        FileDateToolTip:
          "Date of file creation. It may not equal date of capture.",
        ClearanceSystem: "Clearance System",
        ClearanceSystemTooltip: "Clearance System used by the file.",
        Date: {
          Month: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
        },
      },
      TOOLS: {
        Header: "Tools",
        Measures: {
          Header: "Measures",
          Height: "Height Measurement",
          Distance: "Distance Measurement",
          RemoveAll: "Clear measurements.",
        },
        Units: {
          Header: "Unit",
          Meter: "Meters",
          Feet: "Feet",
          Inch: "Inches",
        },
        Navigation: {
          Header: "Navigation",
          Controls: {
            Earth: "Earth Controls",
            Flight: "Flight controls",
            Home: "Fit pointcloud in view",
            EntryLocation: "Return to starting view",
          },
        },
        Volumes: {
          Header: "Annotations",
        },
      },
      FILTERS: {
        Header: "Filters",
        Coloration: {
          Header: "Coloration",
          RawColor: "Raw Color",
          RawColorTooltip: "Colors from lidar file only.",
          RawColorTooltipMissingColor:
            "This lidar file doesn't have any colors to display.",
          Highlighted: "Highlighted",
          HighlightedTooltip: "Lidar file colors but highlighted classes.",
          HighlightedTooltipMissingColor:
            "Highlights do not work in files without color.",
          Clearance: "Clearance",
          ClearanceTooltip: "Color points based on clearance.",
          ClearanceTooptipMissingClearance:
            "Clearance analysis has not been done on this file.",
          Classifications: "Classifications",
          ClassificationsTooltip: "Color points based on classification.",
        },
        ClassificationSource: "Classification Source",
        SourceSkyqraft: "Arkion",
        SourceExternal: "Unknown",
        Classifications: {
          Header: "Shown Classes",
          ToggleAll: "Toggle All",
        },
      },
      FEEDBACK: {
        Header: "File Feedback",
        Subheader: "Send feedback on the file to Arkion.",
        Includes:
          "This feedback will include your current location in pointcloud view.",
        Placeholder: "Enter feedback...",
        Send: "Send",
        SendConfirm: "Are you certain you want to send this feedback?",
      },
      DISPLAY: {
        Header: "Display",
        PointBudget: "Point Budget",
        FieldOfView: "Field of View",
        MinPointSize: "Minimum Point Size",
        DemoMode: "Demo Mode",
        Technical: {
          Header: "Technical",
          ShowBoundingBoxes: "Show Bounding Boxes",
          DebugPrint: "Print Debug Data",
        },
      },
      CloseMenu: "Close Menu",
    },
    CLASSIFIERS: {
      ClipOther: "Classify as Other",
      ClipGround: "Classify as Ground",
      ClipWire: "Classify as Wire",
      ClipPole: "Classify as Pole",
      ClipOtherWire: "Reclassify Other as Wire",
      ClipOtherPole: "Reclassify Other as Pole",
      ClipPowerlineOther: "Reclassify Powerline as Other",
      ClipLowVegetation: "Classify as Low Vegetation",
      ClipMediumVegetation: "Classify as Medium Vegetation",
      ClipHighVegetation: "Classify as High Vegetation",
      ClipBuilding: "Classify as Building",
      ClipNoise: "Classify as Noise",
      ClipWater: "Classify as Water",
      ClipGuyWire: "Classify as Guy Wire",
      ClipRoad: "Classify as Road",
    },
    DEFECTS: {
      Tooltip: "Add defect",
      Fallback: "No defect type selected",
    },
    LOG_OUT: {
      Confirm: "Are you sure you want to log out?\nPress OK to confirm.",
    },
    VOLUMES: {
      ConfirmLoad:
        "Are you sure you want to load annotations?\nAny local changes made on existing boxes will be overwritten.",
      ConfirmSave: "Are you sure you want to save your changes?",
      SaveSuccess: "Succesfully uploaded annotations!",
      SaveFailure: "Error uploading annotations:",
      SaveNoChanges: "You haven't made any changes.",
      DemoSaveWarning: "Saving is not allowed in demo mode.",
      DemoSaveResponse: "Annotation save response! (Demo Mode)",
    },
    REDIRECT: {
      NO_VOLUMES:
        "Volumes aren't editable in a linked scene. Open the original scene to edit volumes.",
    },
  },
};
