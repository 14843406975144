export function addLineNumbers(string: string): string {
  const lines = string.split("\n");

  for (let i = 0; i < lines.length; i++) {
    lines[i] = i + 1 + ": " + lines[i];
  }

  return lines.join("\n");
}

export function filterEmptyLine(string: string): boolean {
  return string !== "";
}
