import { EventDispatcher } from "potree/events";
import { Scene } from "potree/object3d";

export class ToolBase extends EventDispatcher {
  viewer;
  renderer;
  active = true;

  scene = new Scene();

  constructor(viewer, scene_name: string, active_by_default: boolean) {
    super();
    this.viewer = viewer;
    this.active = active_by_default;

    this.scene.name = scene_name;

    if (this.active) {
      this.viewer.inputHandler.registerInteractiveScene(this.scene);
    }

    viewer.addEventListener("scene_context_changed", () => {
      let oldScene = this.scene;
      this.scene = new Scene();

      if (this.active) {
        this.viewer.inputHandler.unregisterInteractiveScene(oldScene);
        this.viewer.inputHandler.registerInteractiveScene(this.scene);
      }
    });
  }

  setActive(active: boolean) {
    if (this.active === active) return;
    this.active = active;

    if (active) {
      this.viewer.inputHandler.registerInteractiveScene(this.scene);
    } else {
      this.viewer.inputHandler.unregisterInteractiveScene(this.scene);
    }
  }
}
