import { Matrix4, Sphere, Vector3 } from "potree/mathtypes";
import { Object3D } from "potree/object3d";
import * as MathUtils from "../utils/math";
import { Ray } from "potree/raycasting";

export class Camera extends Object3D {
  isCamera = true;

  constructor() {
    super();

    this.type = "Camera";

    this.matrixWorldInverse = new Matrix4();

    this.projectionMatrix = new Matrix4();
    this.projectionMatrixInverse = new Matrix4();
  }

  copy(source, recursive) {
    Object3D.prototype.copy.call(this, source, recursive);

    this.matrixWorldInverse.copy(source.matrixWorldInverse);

    this.projectionMatrix.copy(source.projectionMatrix);
    this.projectionMatrixInverse.copy(source.projectionMatrixInverse);

    return this;
  }

  getWorldDirection(target) {
    this.updateWorldMatrix(true, false);

    const e = this.matrixWorld.elements;

    return target.set(-e[8], -e[9], -e[10]).normalize();
  }

  updateMatrixWorld(force) {
    Object3D.prototype.updateMatrixWorld.call(this, force);

    this.matrixWorldInverse.copy(this.matrixWorld).invert();
  }

  updateWorldMatrix(updateParents, updateChildren) {
    Object3D.prototype.updateWorldMatrix.call(
      this,
      updateParents,
      updateChildren,
    );

    this.matrixWorldInverse.copy(this.matrixWorld).invert();
  }

  unprojectVector3(vector) {
    return vector
      .applyMatrix4(this.projectionMatrixInverse)
      .applyMatrix4(this.matrixWorld);
  }

  setRaycaster(ray, direction) {}

  clone() {
    return new Camera().copy(this);
  }
}

export class PerspectiveCamera extends Camera {
  isPerspectiveCamera = true;

  constructor(
    fov = 50,
    aspect = 1,
    near = 0.1,
    far = 2000,
    instance_counter = 0,
  ) {
    super();

    this.instance_counter = 0;

    this.type = "PerspectiveCamera";

    this.fov = fov;
    this.zoom = 1;

    this.near = near;
    this.far = far;
    this.focus = 10;

    this.aspect = aspect;
    this.view = null;

    this.filmGauge = 35; // width of the film (default in millimeters)
    this.filmOffset = 0; // horizontal film offset (same unit as gauge)

    this.updateProjectionMatrix();
  }
  zoomTo(node, factor) {
    if (!node.geometry && !node.boundingSphere && !node.boundingBox) {
      return;
    }

    if (node.geometry && node.geometry.boundingSphere === null) {
      node.geometry.computeBoundingSphere();
    }

    node.updateMatrixWorld();

    let boundingSphere;

    if (node.boundingSphere) {
      boundingSphere = node.boundingSphere;
    } else if (node.geometry && node.geometry.boundingSphere) {
      boundingSphere = node.geometry.boundingSphere;
    } else {
      boundingSphere = node.boundingBox.getBoundingSphere(new Sphere());
    }

    let _factor = factor || 1;

    boundingSphere = boundingSphere.clone().applyMatrix4(node.matrixWorld);
    let radius = boundingSphere.radius;
    let fovr = (this.fov * Math.PI) / 180;

    if (this.aspect < 1) {
      fovr = fovr * this.aspect;
    }

    let distanceFactor = Math.abs(radius / Math.sin(fovr / 2)) * _factor;

    let offset = this.getWorldDirection(new Vector3()).multiplyScalar(
      -distanceFactor,
    );
    this.position.copy(boundingSphere.center.clone().add(offset));
  }

  copy(source, recursive) {
    Camera.prototype.copy.call(this, source, recursive);

    this.fov = source.fov;
    this.zoom = source.zoom;

    this.near = source.near;
    this.far = source.far;
    this.focus = source.focus;

    this.aspect = source.aspect;
    this.view = source.view === null ? null : { ...source.view };

    this.filmGauge = source.filmGauge;
    this.filmOffset = source.filmOffset;

    return this;
  }

  clone() {
    return new PerspectiveCamera().copy(this);
  }

  setRaycaster(raycaster, position) {
    raycaster.ray.origin.setFromMatrixPosition(this.matrixWorld);
    raycaster.ray.direction.set(position.x, position.y, 0.5);

    this.unprojectVector3(raycaster.ray.direction);

    raycaster.ray.direction.sub(raycaster.ray.origin).normalize();
    raycaster.camera = this;
  }

  /**
   * Sets the FOV by focal length in respect to the current .filmGauge.
   *
   * The default film gauge is 35, so that the focal length can be specified for
   * a 35mm (full frame) camera.
   *
   * Values for focal length and film gauge must have the same unit.
   */
  setFocalLength(focalLength) {
    // see http://www.bobatkins.com/photography/technical/field_of_view.html
    const vExtentSlope = (0.5 * this.getFilmHeight()) / focalLength;

    this.fov = MathUtils.RAD2DEG * 2 * Math.atan(vExtentSlope);
    this.updateProjectionMatrix();
  }

  /**
   * Calculates the focal length from the current .fov and .filmGauge.
   */
  getFocalLength() {
    const vExtentSlope = Math.tan(MathUtils.DEG2RAD * 0.5 * this.fov);

    return (0.5 * this.getFilmHeight()) / vExtentSlope;
  }

  getEffectiveFOV() {
    return (
      MathUtils.RAD2DEG *
      2 *
      Math.atan(Math.tan(MathUtils.DEG2RAD * 0.5 * this.fov) / this.zoom)
    );
  }

  getFilmWidth() {
    // film not completely covered in portrait format (aspect < 1)
    return this.filmGauge * Math.min(this.aspect, 1);
  }

  getFilmHeight() {
    // film not completely covered in landscape format (aspect > 1)
    return this.filmGauge / Math.max(this.aspect, 1);
  }

  /*
   * Sets an offset in a larger frustum. This is useful for multi-window or
   * multi-monitor/multi-machine setups.
   *
   * For example, if you have 3x2 monitors and each monitor is 1920x1080 and
   * the monitors are in grid like this
   *
   *   +---+---+---+
   *   | A | B | C |
   *   +---+---+---+
   *   | D | E | F |
   *   +---+---+---+
   *
   * then for each monitor you would call it like this
   *
   *   const w = 1920;
   *   const h = 1080;
   *   const fullWidth = w * 3;
   *   const fullHeight = h * 2;
   *
   *   --A--
   *   camera.setViewOffset( fullWidth, fullHeight, w * 0, h * 0, w, h );
   *   --B--
   *   camera.setViewOffset( fullWidth, fullHeight, w * 1, h * 0, w, h );
   *   --C--
   *   camera.setViewOffset( fullWidth, fullHeight, w * 2, h * 0, w, h );
   *   --D--
   *   camera.setViewOffset( fullWidth, fullHeight, w * 0, h * 1, w, h );
   *   --E--
   *   camera.setViewOffset( fullWidth, fullHeight, w * 1, h * 1, w, h );
   *   --F--
   *   camera.setViewOffset( fullWidth, fullHeight, w * 2, h * 1, w, h );
   *
   *   Note there is no reason monitors have to be the same size or in a grid.
   */
  setViewOffset(fullWidth, fullHeight, x, y, width, height) {
    this.aspect = fullWidth / fullHeight;

    if (this.view === null) {
      this.view = {
        enabled: true,
        fullWidth: 1,
        fullHeight: 1,
        offsetX: 0,
        offsetY: 0,
        width: 1,
        height: 1,
      };
    }

    this.view.enabled = true;
    this.view.fullWidth = fullWidth;
    this.view.fullHeight = fullHeight;
    this.view.offsetX = x;
    this.view.offsetY = y;
    this.view.width = width;
    this.view.height = height;

    this.updateProjectionMatrix();
  }

  clearViewOffset() {
    if (this.view !== null) {
      this.view.enabled = false;
    }

    this.updateProjectionMatrix();
  }

  updateProjectionMatrix() {
    const near = this.near;
    let top = (near * Math.tan(MathUtils.DEG2RAD * 0.5 * this.fov)) / this.zoom;
    let height = 2 * top;
    let width = this.aspect * height;
    let left = -0.5 * width;
    const view = this.view;

    if (this.view !== null && this.view.enabled) {
      const fullWidth = view.fullWidth,
        fullHeight = view.fullHeight;

      left += (view.offsetX * width) / fullWidth;
      top -= (view.offsetY * height) / fullHeight;
      width *= view.width / fullWidth;
      height *= view.height / fullHeight;
    }

    const skew = this.filmOffset;
    if (skew !== 0) left += (near * skew) / this.getFilmWidth();

    this.projectionMatrix.makePerspective(
      left,
      left + width,
      top,
      top - height,
      near,
      this.far,
    );

    this.projectionMatrixInverse.copy(this.projectionMatrix).invert();
  }
}

export class OrthographicCamera extends Camera {
  isOrthographicCamera = true;

  constructor(
    left = -1,
    right = 1,
    top = 1,
    bottom = -1,
    near = 0.1,
    far = 2000,
  ) {
    super();

    this.type = "OrthographicCamera";

    this.zoom = 1;
    this.view = null;

    this.left = left;
    this.right = right;
    this.top = top;
    this.bottom = bottom;

    this.near = near;
    this.far = far;

    this.updateProjectionMatrix();
  }
  zoomTo(node, factor = 1) {
    if (!node.geometry && !node.boundingBox) {
      return;
    }

    this.updateProjectionMatrix();
  }

  copy(source, recursive) {
    Camera.prototype.copy.call(this, source, recursive);

    this.left = source.left;
    this.right = source.right;
    this.top = source.top;
    this.bottom = source.bottom;
    this.near = source.near;
    this.far = source.far;

    this.zoom = source.zoom;
    this.view = source.view === null ? null : { ...source.view };

    return this;
  }

  setRaycaster(raycaster, position) {
    raycaster.ray.origin.set(
      position.x,
      position.y,
      (this.near + this.far) / (this.near - this.far),
    );
    this.unprojectVector3(raycaster.ray.origin); // set origin in plane of camera
    raycaster.ray.direction.set(0, 0, -1).transformDirection(this.matrixWorld);
    this.camera = this;
  }

  setViewOffset(fullWidth, fullHeight, x, y, width, height) {
    if (this.view === null) {
      this.view = {
        enabled: true,
        fullWidth: 1,
        fullHeight: 1,
        offsetX: 0,
        offsetY: 0,
        width: 1,
        height: 1,
      };
    }

    this.view.enabled = true;
    this.view.fullWidth = fullWidth;
    this.view.fullHeight = fullHeight;
    this.view.offsetX = x;
    this.view.offsetY = y;
    this.view.width = width;
    this.view.height = height;

    this.updateProjectionMatrix();
  }

  clearViewOffset() {
    if (this.view !== null) {
      this.view.enabled = false;
    }

    this.updateProjectionMatrix();
  }

  updateProjectionMatrix() {
    const dx = (this.right - this.left) / (2 * this.zoom);
    const dy = (this.top - this.bottom) / (2 * this.zoom);
    const cx = (this.right + this.left) / 2;
    const cy = (this.top + this.bottom) / 2;

    let left = cx - dx;
    let right = cx + dx;
    let top = cy + dy;
    let bottom = cy - dy;

    if (this.view !== null && this.view.enabled) {
      const scaleW = (this.right - this.left) / this.view.fullWidth / this.zoom;
      const scaleH =
        (this.top - this.bottom) / this.view.fullHeight / this.zoom;

      left += scaleW * this.view.offsetX;
      right = left + scaleW * this.view.width;
      top -= scaleH * this.view.offsetY;
      bottom = top - scaleH * this.view.height;
    }

    this.projectionMatrix.makeOrthographic(
      left,
      right,
      top,
      bottom,
      this.near,
      this.far,
    );

    this.projectionMatrixInverse.copy(this.projectionMatrix).invert();
  }
}
