//@ts-check
import { Matrix3, Matrix4, Plane, Sphere, Vector2, Vector3 } from "./mathtypes";
import { Object3D } from "./object3d";
import { BackSide, DoubleSide } from "./rendering/constants";
import { SpriteMaterial } from "./rendering/material";
import { SQBuffer, SQPlaneBuffer } from "./nova_renderer/buffer";
import { Ray } from "./raycasting";

const _intersectionPointWorld = new Vector3();

export function checkIntersection(
  object,
  material,
  raycaster,
  ray,
  pA,
  pB,
  pC,
  point,
) {
  // biome-ignore lint/suspicious/noImplicitAnyLet: No type in ray yet
  let intersect;

  if (material.side === BackSide) {
    intersect = ray.intersectTriangle(pC, pB, pA, true, point);
  } else {
    intersect = ray.intersectTriangle(
      pA,
      pB,
      pC,
      material.side !== DoubleSide,
      point,
    );
  }

  if (intersect === null) return null;

  _intersectionPointWorld.copy(point);
  _intersectionPointWorld.applyMatrix4(object.matrixWorld);

  const distance = raycaster.ray.origin.distanceTo(_intersectionPointWorld);

  if (distance < raycaster.near || distance > raycaster.far) return null;

  return {
    distance: distance,
    point: _intersectionPointWorld.clone(),
    object: object,
  };
}

const _sphere$1 = /*@__PURE__*/ new Sphere();
const _vector$5 = /*@__PURE__*/ new Vector3();

export class Frustum {
  planes: Plane[] = [];

  constructor(p0, p1, p2, p3, p4, p5) {
    this.planes = [
      p0 ?? new Plane(),
      p1 ?? new Plane(),
      p2 ?? new Plane(),
      p3 ?? new Plane(),
      p4 ?? new Plane(),
      p5 ?? new Plane(),
    ];
  }

  /**
   * @param {Plane} p0
   * @param {Plane} p1
   * @param {Plane} p2
   * @param {Plane} p3
   * @param {Plane} p4
   * @param {Plane} p5
   */
  set(p0, p1, p2, p3, p4, p5) {
    const planes = this.planes;

    planes[0].copy(p0);
    planes[1].copy(p1);
    planes[2].copy(p2);
    planes[3].copy(p3);
    planes[4].copy(p4);
    planes[5].copy(p5);

    return this;
  }

  copy(source: this): this {
    for (let i = 0; i < 6; i++) {
      this.planes[i].copy(source.planes[i]);
    }
    return this;
  }

  clone<T extends this>(this: T): T {
    return new (this.constructor as { new (): T })().copy(this);
  }

  setFromProjectionMatrix(m) {
    const planes = this.planes;
    const me = m.elements;
    const me0 = me[0];
    const me1 = me[1];
    const me2 = me[2];
    const me3 = me[3];
    const me4 = me[4];
    const me5 = me[5];
    const me6 = me[6];
    const me7 = me[7];
    const me8 = me[8];
    const me9 = me[9];
    const me10 = me[10];
    const me11 = me[11];
    const me12 = me[12];
    const me13 = me[13];
    const me14 = me[14];
    const me15 = me[15];

    planes[0]
      .setComponents(me3 - me0, me7 - me4, me11 - me8, me15 - me12)
      .normalize();
    planes[1]
      .setComponents(me3 + me0, me7 + me4, me11 + me8, me15 + me12)
      .normalize();
    planes[2]
      .setComponents(me3 + me1, me7 + me5, me11 + me9, me15 + me13)
      .normalize();
    planes[3]
      .setComponents(me3 - me1, me7 - me5, me11 - me9, me15 - me13)
      .normalize();
    planes[4]
      .setComponents(me3 - me2, me7 - me6, me11 - me10, me15 - me14)
      .normalize();
    planes[5]
      .setComponents(me3 + me2, me7 + me6, me11 + me10, me15 + me14)
      .normalize();

    return this;
  }

  intersectsObject(object) {
    const geometry = object.geometry;

    if (geometry.boundingSphere === null) geometry.computeBoundingSphere();

    _sphere$1.copy(geometry.boundingSphere).applyMatrix4(object.matrixWorld);

    return this.intersectsSphere(_sphere$1);
  }

  intersectsSprite(sprite) {
    _sphere$1.center.set(0, 0, 0);
    _sphere$1.radius = 0.7071067811865476;
    _sphere$1.applyMatrix4(sprite.matrixWorld);

    return this.intersectsSphere(_sphere$1);
  }

  intersectsSphere(sphere) {
    const planes = this.planes;
    const center = sphere.center;
    const negRadius = -sphere.radius;

    for (let i = 0; i < 6; i++) {
      const distance = planes[i].distanceToPoint(center);

      if (distance < negRadius) {
        return false;
      }
    }

    return true;
  }

  intersectsBox(box) {
    const planes = this.planes;

    for (let i = 0; i < 6; i++) {
      const plane = planes[i];

      // corner at max distance

      _vector$5.x = plane.normal.x > 0 ? box.max.x : box.min.x;
      _vector$5.y = plane.normal.y > 0 ? box.max.y : box.min.y;
      _vector$5.z = plane.normal.z > 0 ? box.max.z : box.min.z;

      if (plane.distanceToPoint(_vector$5) < 0) {
        return false;
      }
    }

    return true;
  }

  containsPoint(point) {
    const planes = this.planes;

    for (let i = 0; i < 6; i++) {
      if (planes[i].distanceToPoint(point) < 0) {
        return false;
      }
    }

    return true;
  }
}

type Intersection = {
  distance: number;
  point: Vector3;
  object: Object3D;
  faceIndex?: number;
} | null;

function checkSQBoxIntersection(
  object,
  raycaster,
  ray,
  pA,
  pB,
  pC,
  point,
): Intersection {
  const intersect = ray.intersectTriangle(pA, pB, pC, true, point);

  if (intersect === null) return null;

  _intersectionPointWorld.copy(point);
  _intersectionPointWorld.applyMatrix4(object.matrixWorld);

  const distance = raycaster.ray.origin.distanceTo(_intersectionPointWorld);

  if (distance < raycaster.near || distance > raycaster.far) return null;

  return {
    distance: distance,
    point: _intersectionPointWorld.clone(),
    object: object,
  };
}

const _intersectPoint = new Vector3();
const _inverseMatrix = new Matrix4();
const _ray = new Ray();
const _sphere = new Sphere();

const _pA = new Vector3();
const _pB = new Vector3();
const _pC = new Vector3();

export class Mesh extends Object3D {
  /**
   * @param {SQBuffer} geometry
   * @param {import("./rendering/material").Material} [material]
   */
  type = "Mesh";
  isMesh = true;
  material = null;
  geometry = null;

  constructor(geometry, material) {
    super();
    this.geometry = geometry;
    this.material = material;
  }

  copy(source) {
    Object3D.prototype.copy.call(this, source);

    this.material = source.material;
    this.geometry = source.geometry;

    return this;
  }

  raycast(raycaster, intersects) {
    const geometry = this.geometry;
    const material = this.material;
    const matrixWorld = this.matrixWorld;

    if (material === undefined) return;

    // Checking boundingSphere distance to ray
    if (geometry.boundingSphere === null) geometry.computeBoundingSphere();

    _sphere.copy(geometry.boundingSphere);
    _sphere.applyMatrix4(matrixWorld);

    if (raycaster.ray.intersectsSphere(_sphere) === false) return;

    _inverseMatrix.copy(matrixWorld).invert();
    _ray.copy(raycaster.ray).applyMatrix4(_inverseMatrix);

    // Check boundingBox before continuing
    if (geometry.boundingBox !== null) {
      if (_ray.intersectsBox(geometry.boundingBox) === false) return;
    }

    let intersection: Intersection;

    if (geometry instanceof SQBuffer) {
      const indices = geometry.indices;
      const vertices = geometry.vertices;

      for (let i = 0; i < indices.length; i += 3) {
        const a = indices[i] * 3;
        const b = indices[i + 1] * 3;
        const c = indices[i + 2] * 3;

        _pA.set(vertices[a], vertices[a + 1], vertices[a + 2]);
        _pB.set(vertices[b], vertices[b + 1], vertices[b + 2]);
        _pC.set(vertices[c], vertices[c + 1], vertices[c + 2]);

        intersection = checkSQBoxIntersection(
          this,
          raycaster,
          _ray,
          _pA,
          _pB,
          _pC,
          _intersectPoint,
        );

        if (intersection) {
          intersection.faceIndex = Math.floor(i / 3); // triangle number in indexed buffer semantics
          intersects.push(intersection);
        }
      }
    }
  }
}

let _spriteGeometry: SQPlaneBuffer | undefined;

export class Sprite extends Object3D {
  type = "Sprite";
  isSprite = true;
  geometry: SQPlaneBuffer;
  material: SpriteMaterial;
  center: Vector2;

  constructor(material) {
    super();
    if (_spriteGeometry === undefined) {
      _spriteGeometry = new SQPlaneBuffer(1.0, 1.0);
    }

    this.geometry = _spriteGeometry;
    this.material = material ?? new SpriteMaterial();

    this.center = new Vector2(0.5, 0.5);
  }

  copy(source) {
    Object3D.prototype.copy.call(this, source);

    if (source.center !== undefined) this.center.copy(source.center);

    this.material = source.material;

    return this;
  }
}
