import { clamp } from "potree/utils/math";
import { ToolBase } from "./base";
import { CatmullRomCurve3 } from "potree/utils/curve";
import { Vector3 } from "potree/mathtypes";

class CameraPathPoint {
  position: Vector3;
  look_direction: Vector3;

  constructor(position: Vector3, look_direction: Vector3) {
    this.position = position;
    this.look_direction = look_direction;
  }
}

export class CameraPathTool extends ToolBase {
  #points: CameraPathPoint[] = [];
  #isPlaying = false;
  #startTime = 0;

  duration = 10.0;
  curveType = "centripetal";

  #positionCurve = null;
  #targetCurve = null;

  constructor(viewer) {
    super(viewer, "camera_path_points", true);

    this.curveType = "centripetal";
    this.viewer.addEventListener("update", this.update.bind(this));
  }

  play() {
    if (this.#positionCurve === null) {
      return;
    }

    this.#isPlaying = true;
    this.#startTime = performance.now();
  }
  pushPoint() {
    let view = this.viewer.sceneContext.view;
    this.#points.push(
      new CameraPathPoint(view.position.clone(), view.direction)
    );

    this.recalculateCurves();
  }
  eraseLastPoint() {
    this.#points.pop();
    this.recalculateCurves();
  }

  recalculateCurves() {
    {
      // positions
      const positions = this.#points.map((cp) => cp.position);

      const curve = new CatmullRomCurve3(positions);
      curve.curveType = this.curveType;

      this.#positionCurve = curve;
    }

    {
      // targets
      const directions = this.#points.map((cp) => cp.look_direction);

      const curve = new CatmullRomCurve3(directions);
      curve.curveType = this.curveType;

      this.#targetCurve = curve;
    }
  }

  at(t: number) {
    t = clamp(t, 0.0, 1.0);

    const camPos = this.#positionCurve.getPointAt(t);
    const direction = this.#targetCurve.getPointAt(t);

    const frame = {
      position: camPos,
      direction: direction,
    };

    return frame;
  }

  update() {
    if (!this.#isPlaying) {
      return;
    }

    let elapsedTime = (performance.now() - this.#startTime) / 1000;

    const progress = elapsedTime / this.duration;

    let frame = this.at(progress);

    let view = this.viewer.sceneContext.view;
    view.position.copy(frame.position);
    view.direction = frame.direction;

    if (progress >= 1.0) {
      this.#isPlaying = false;
    }
  }
}
