import { captureException } from "@sentry/react";
import axios from "axios";
import { BASE_URI } from "config";

import { EventDispatcher } from "potree/events";
import { Vector2 } from "potree/mathtypes";
import { Viewer } from "potree/potree";

export class ScreenshotTool extends EventDispatcher {
  #viewer: Viewer;

  /**
   * Screenshots are constrained to A4 ratio.
   * Multiply by this to convert width to height.
   */
  static A4_RATIO = 210.0 / 297.0;

  startPoint: Vector2 = null;

  constructor(viewer: Viewer) {
    super();
    this.#viewer = viewer;

    this.addEventListener("mousedown", this.screenshotClick.bind(this));
    this.addEventListener("keydown", this.keydown.bind(this));
  }

  /** Enter "Screenshot-mode" */
  startScreenshot() {
    this.#viewer.inputHandler.addInputListener(this);
  }

  /** Mouse click while in screenshot mode */
  screenshotClick(event: { mouse: Vector2 }) {
    const rect = this.#viewer.renderContext.canvas.getBoundingClientRect();

    // Calculate coordinates relative to canvas
    const x = event.mouse.x - rect.left;
    const y = event.mouse.y - rect.top;

    // First click.
    if (!this.startPoint) {
      this.startPoint = new Vector2(x, y);
    } else {
      // Clamping endPoint to make sure it stays within the canvas bounds.
      const endPoint = new Vector2(x, y).clamp(
        new Vector2(0.0, 0.0),
        new Vector2(rect.right, rect.bottom)
      );

      // Calculate the absolute delta between the start and end point.
      // This gives us the distance on each axis separately.
      const delta = this.startPoint.clone().sub(endPoint).abs();

      // Enforce a laying A4 format by taking the delta on the greater axis
      // Using that we can construct the delta on the other axis.

      // Convert width to the expected height using the ratio.
      const expectedHeightFromWidth = delta.width * ScreenshotTool.A4_RATIO;
      if (expectedHeightFromWidth > delta.height) {
        // Calculate height from width... Which is actually deltaWidth
        delta.height = expectedHeightFromWidth;
      } else {
        delta.width = delta.height / ScreenshotTool.A4_RATIO;
      }

      // Using delta we can calculate the corner of the screenshot.
      const position = this.startPoint.clone().sub(delta).max(new Vector2());
      const size = delta.multiplyScalar(2.0);

      this.takeScreenshot(position.x, position.y, size.width, size.height);

      this.clearScreenshotMode();
    }
  }

  takeScreenshot(x: number, y: number, width: number, height: number) {
    // Explicitly round values
    // Prevent issues with floating points being rounded differently.
    x = Math.floor(x);
    y = Math.floor(y);
    width = Math.ceil(width);
    height = Math.ceil(height);

    const gl = this.#viewer.renderContext.gl;

    // Allocate pixel buffer to copy image data into.
    const PIXEL_BYTES = 4; // 4 = RGBA
    const pixels = new Uint8Array(width * height * PIXEL_BYTES);

    // Read the pixels from the current framebuffer
    gl.readPixels(
      x,
      y, // Start coordinates
      width,
      height, // Width and height of the area
      gl.RGBA, // Format
      gl.UNSIGNED_BYTE, // Data type
      pixels // Destination buffer
    );

    // WebGL canvas has a flipped Y (0 is top-left) compared to regular html (0 if bottom-left).
    // So we need to flip the pixels.
    const flippedPixels = new Uint8Array(pixels.length);

    const ROW_LENGTH = width * PIXEL_BYTES;
    for (let row = 0; row < height; row++) {
      const srcStart = row * ROW_LENGTH;
      const dstStart = (height - row - 1) * ROW_LENGTH;

      flippedPixels.set(
        pixels.subarray(srcStart, srcStart + ROW_LENGTH),
        dstStart
      );
    }

    // We need to create a canvas tof the screenshot's size to copy the data back into.
    // As well as to convert the data into a suitable format (JPEG)
    const outputCanvas = document.createElement("canvas");
    outputCanvas.width = width;
    outputCanvas.height = height;
    const context = outputCanvas.getContext("2d");

    // Create & fill image data.
    const imageData = context.createImageData(width, height);
    imageData.data.set(flippedPixels);

    // Draw the image data on canvas
    context.putImageData(imageData, 0, 0);

    // Convert to image.
    outputCanvas.toBlob(this.sendImageBlob, "image/jpeg");
  }

  sendImageBlob = (blob: Blob) => {
    const view = this.#viewer.sceneContext.view;
    const viewPos = view.position;
    const [lng, lat, alt] = this.#viewer.convertLidarToWSGS84(
      viewPos.x,
      viewPos.y,
      viewPos.z
    );

    const metadata = {
      lat: lat,
      lng: lng,
      alt: alt,
      pitch: view.pitch,
      compass_dir: view.yaw,
    };

    const formData = new FormData();
    formData.append("file", blob, "screenshot.jpeg"); // Append Blob as a file
    formData.append("metadata", JSON.stringify(metadata));

    const projectId = this.#viewer.getProject();
    const scene = this.#viewer.getScene();

    axios
      .post(`${BASE_URI}/potree/screenshot/${projectId}/${scene}`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data", // Required for file uploads          ...header,
        },
      })
      .catch((error) => {
        const exceptionHint = {
          event_id: "potree.tools.ScreenshotTool.sendImageBlob",
          originalException: error,
        };

        captureException(error, exceptionHint);
      });
  };

  clearScreenshotMode() {
    this.startPoint = null;

    this.#viewer.inputHandler.removeInputListener(this);
  }

  keydown(event: { type: string; event: KeyboardEvent }) {
    if (event.event.key === "Escape") {
      this.clearScreenshotMode();
    }
  }
}
