import { RGBAFormat, RGBFormat } from "./constants";
import { Texture } from "./types";

function loadImage(url: string) {
  return new Promise(
    (
      resolve: (value: HTMLImageElement) => void,
      reject: (reason?: string | Event) => void
    ) => {
      const image = new Image();

      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);

      image.src = url;
    }
  );
}

export function loadTexture(
  url: string,
  onLoad?: (texture: Texture) => void,
  onError?: (error: string | Event) => void
) {
  const texture = new Texture();

  loadImage(url)
    .then((image) => {
      texture.image = image;

      // JPEGs don't have an alpha channel, so store them as RGB.
      const isJPEG =
        /\.jpe?g($|\?)/i.test(url) || /^data:image\/jpeg/.test(url);
      texture.format = isJPEG ? RGBFormat : RGBAFormat;
      texture.needsUpdate = true;

      if (onLoad) {
        onLoad(texture);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });

  return texture;
}
