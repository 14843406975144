import { EventDispatcher } from "./events";
import {
  Euler,
  Layers,
  Matrix3,
  Matrix4,
  Quaternion,
  Sphere,
  Vector3,
} from "./mathtypes";
import { Ray } from "./raycasting";
import { Float32BufferAttribute } from "./rendering/bufferattribute";
import { BufferGeometry } from "./rendering/buffers";
import { LineBasicMaterial } from "./rendering/material";
import { generateUUID } from "./utils/math";

let _object3DId = 0;

const _v1$2 = new Vector3();
const _q1 = new Quaternion();
const _m1$1 = new Matrix4();
const _target = new Vector3();

const _position = new Vector3();
const _scale = new Vector3();
const _quaternion$2 = new Quaternion();

const _xAxis = new Vector3(1, 0, 0);
const _yAxis = new Vector3(0, 1, 0);
const _zAxis = new Vector3(0, 0, 1);

export class Object3D extends EventDispatcher {
  isObject3D = true;

  position = new Vector3();
  rotation = new Euler();
  quaternion = new Quaternion();
  scale = new Vector3(1, 1, 1);
  boundingBox = null;
  uuid: string = generateUUID();

  name = "";
  type = "Object3D";

  parent?: Object3D = null;
  children: Object3D[] = [];

  up: Vector3;
  static DefaultUp: Vector3 = new Vector3(0, 1, 0);

  /** Matrix transformation in local space. */
  matrix: Matrix4 = new Matrix4();
  /** Matrix transformation in world space. */
  matrixWorld: Matrix4 = new Matrix4();
  /** Matrix transformation in camera-view space. Used for rendering. */
  modelViewMatrix: Matrix4 = new Matrix4();
  /** Rotation only matrix used for normal maps? I don't think we use this. */
  normalMatrix: Matrix3 = new Matrix3();

  matrixAutoUpdate: boolean;
  matrixWorldNeedsUpdate: boolean = false;
  static DefaultMatrixAutoUpdate: boolean = true;

  /** Bitmask stating which "layers" this object belongs to for collision & rendering. */
  layers: Layers = new Layers();

  visible: boolean = true;

  constructor() {
    super();
    Object.defineProperty(this, "id", { value: _object3DId++ });

    this.up = Object3D.DefaultUp.clone();

    function onRotationChange() {
      this.quaternion.setFromEuler(this.rotation, false);
    }

    function onQuaternionChange() {
      this.rotation.setFromQuaternion(this.quaternion, undefined, false);
    }

    this.rotation._onChange(onRotationChange.bind(this));
    this.quaternion._onChange(onQuaternionChange.bind(this));

    this.matrixAutoUpdate = Object3D.DefaultMatrixAutoUpdate;
  }

  applyMatrix4(matrix: Matrix4) {
    if (this.matrixAutoUpdate) this.updateMatrix();

    this.matrix.premultiply(matrix);

    this.matrix.decompose(this.position, this.quaternion, this.scale);
  }

  applyQuaternion(q: Quaternion) {
    this.quaternion.premultiply(q);

    return this;
  }

  setRotationFromAxisAngle(axis: Vector3, angle: number) {
    // assumes axis is normalized

    this.quaternion.setFromAxisAngle(axis, angle);
  }

  setRotationFromEuler(euler: Euler) {
    this.quaternion.setFromEuler(euler, true);
  }

  setRotationFromMatrix(m: Matrix4) {
    // assumes the upper 3x3 of m is a pure rotation matrix (i.e, unscaled)

    this.quaternion.setFromRotationMatrix(m);
  }

  setRotationFromQuaternion(q: Quaternion) {
    // assumes q is normalized

    this.quaternion.copy(q);
  }

  rotateOnAxis(axis: Vector3, angle: number) {
    // rotate object on axis in object space
    // axis is assumed to be normalized

    _q1.setFromAxisAngle(axis, angle);

    this.quaternion.multiply(_q1);

    return this;
  }

  rotateOnWorldAxis(axis: Vector3, angle: number) {
    // rotate object on axis in world space
    // axis is assumed to be normalized
    // method assumes no rotated parent

    _q1.setFromAxisAngle(axis, angle);

    this.quaternion.premultiply(_q1);

    return this;
  }

  rotateX(angle: number) {
    return this.rotateOnAxis(_xAxis, angle);
  }

  rotateY(angle: number) {
    return this.rotateOnAxis(_yAxis, angle);
  }

  rotateZ(angle: number) {
    return this.rotateOnAxis(_zAxis, angle);
  }

  translateOnAxis(axis: Vector3, distance: number) {
    // translate object by distance along axis in object space
    // axis is assumed to be normalized

    _v1$2.copy(axis).applyQuaternion(this.quaternion);

    this.position.add(_v1$2.multiplyScalar(distance));

    return this;
  }

  translateX(distance: number) {
    return this.translateOnAxis(_xAxis, distance);
  }

  translateY(distance: number) {
    return this.translateOnAxis(_yAxis, distance);
  }

  translateZ(distance: number) {
    return this.translateOnAxis(_zAxis, distance);
  }

  localToWorld(vector: Vector3) {
    return vector.applyMatrix4(this.matrixWorld);
  }

  worldToLocal(vector: Vector3) {
    return vector.applyMatrix4(_m1$1.copy(this.matrixWorld).invert());
  }

  lookAt(x: number | Vector3, y?: number, z?: number) {
    // This method does not support objects having non-uniformly-scaled parent(s)

    if (x instanceof Vector3) {
      _target.copy(x);
    } else {
      _target.set(x, y, z);
    }

    const parent = this.parent;

    this.updateWorldMatrix(true, false);

    _position.setFromMatrixPosition(this.matrixWorld);

    //@ts-ignore This is in no way how OOP should work but we'll get to this when camera is updated.
    if (this.isCamera || this.isLight) {
      _m1$1.lookAt(_position, _target, this.up);
    } else {
      _m1$1.lookAt(_target, _position, this.up);
    }

    this.quaternion.setFromRotationMatrix(_m1$1);

    if (parent) {
      _m1$1.extractRotation(parent.matrixWorld);
      _q1.setFromRotationMatrix(_m1$1);
      this.quaternion.premultiply(_q1.invert());
    }
  }

  /** Add a child to this object. */
  add(object: Object3D) {
    if (arguments.length > 1) {
      for (let i = 0; i < arguments.length; i++) {
        this.add(arguments[i]);
      }

      return this;
    }

    if (object === this) {
      console.error(
        "THREE.Object3D.add: object can't be added as a child of itself.",
        object
      );
      return this;
    }

    if (object.parent !== null) {
      object.parent.remove(object);
    }

    object.parent = this;
    this.children.push(object);

    return this;
  }

  remove(object: Object3D) {
    if (arguments.length > 1) {
      for (let i = 0; i < arguments.length; i++) {
        this.remove(arguments[i]);
      }

      return this;
    }

    const index = this.children.indexOf(object);

    if (index !== -1) {
      object.parent = null;
      this.children.splice(index, 1);
    }

    return this;
  }

  clear() {
    for (let i = 0; i < this.children.length; i++) {
      const object = this.children[i];

      object.parent = null;
    }

    this.children.length = 0;

    return this;
  }

  attach(object: Object3D) {
    // adds object as a child of this, while maintaining the object's world transform

    this.updateWorldMatrix(true, false);

    _m1$1.copy(this.matrixWorld).invert();

    if (object.parent !== null) {
      object.parent.updateWorldMatrix(true, false);

      _m1$1.multiply(object.parent.matrixWorld);
    }

    object.applyMatrix4(_m1$1);

    object.updateWorldMatrix(false, false);

    this.add(object);

    return this;
  }

  getObjectById(id: number) {
    return this.getObjectByProperty("id", id);
  }

  getObjectByName(name: string) {
    return this.getObjectByProperty("name", name);
  }

  getObjectByProperty(name: string, value: any) {
    if (this[name] === value) return this;

    for (let i = 0, l = this.children.length; i < l; i++) {
      const child = this.children[i];
      const object = child.getObjectByProperty(name, value);

      if (object !== undefined) {
        return object;
      }
    }

    return undefined;
  }

  getWorldPosition(target: Vector3) {
    this.updateWorldMatrix(true, false);

    return target.setFromMatrixPosition(this.matrixWorld);
  }

  getWorldQuaternion(target: Quaternion) {
    this.updateWorldMatrix(true, false);

    this.matrixWorld.decompose(_position, target, _scale);

    return target;
  }

  getWorldScale(target: Vector3) {
    this.updateWorldMatrix(true, false);

    this.matrixWorld.decompose(_position, _quaternion$2, target);

    return target;
  }

  getWorldDirection(target: Vector3) {
    this.updateWorldMatrix(true, false);

    const e = this.matrixWorld.elements;

    return target.set(e[8], e[9], e[10]).normalize();
  }

  raycast(raycaster, intersects) {}

  /** Traverse self & children, calling `callback` for each. */
  traverse(callback: (object: Object3D) => void) {
    callback(this);

    const children = this.children;

    for (let i = 0, l = children.length; i < l; i++) {
      children[i].traverse(callback);
    }
  }

  /** Traverse visible self & children, calling `callback` for each. */
  traverseVisible(callback: (object: Object3D) => void) {
    if (this.visible === false) return;

    callback(this);

    const children = this.children;

    for (let i = 0, l = children.length; i < l; i++) {
      children[i].traverseVisible(callback);
    }
  }

  /** Traverse parents & their parents recursive, calling `callback` for each. */
  traverseAncestors(callback: (object: Object3D) => void) {
    const parent = this.parent;

    if (parent !== null) {
      callback(parent);

      parent.traverseAncestors(callback);
    }
  }

  updateMatrix() {
    this.matrix.compose(this.position, this.quaternion, this.scale);

    this.matrixWorldNeedsUpdate = true;
  }

  updateMatrixWorld(force: boolean = false) {
    if (this.matrixAutoUpdate) this.updateMatrix();

    if (this.matrixWorldNeedsUpdate || force) {
      if (this.parent === null) {
        this.matrixWorld.copy(this.matrix);
      } else {
        this.matrixWorld.multiplyMatrices(this.parent.matrixWorld, this.matrix);
      }

      this.matrixWorldNeedsUpdate = false;

      force = true;
    }

    // update children

    const children = this.children;

    for (let i = 0, l = children.length; i < l; i++) {
      children[i].updateMatrixWorld(force);
    }
  }

  updateWorldMatrix(updateParents?: boolean, updateChildren?: boolean) {
    const parent = this.parent;

    if (updateParents === true && parent !== null) {
      parent.updateWorldMatrix(true, false);
    }

    if (this.matrixAutoUpdate) this.updateMatrix();

    if (this.parent === null) {
      this.matrixWorld.copy(this.matrix);
    } else {
      this.matrixWorld.multiplyMatrices(this.parent.matrixWorld, this.matrix);
    }

    // update children

    if (updateChildren === true) {
      const children = this.children;

      for (let i = 0, l = children.length; i < l; i++) {
        children[i].updateWorldMatrix(false, true);
      }
    }
  }

  clone<T extends this>(this: T, recursive?: boolean): T {
    // Clone the current object to a new instance
    // but with the same properties.
    return new (this.constructor as { new (): T })().copy(this, recursive);
  }

  copy(source: Object3D, recursive = true) {
    this.name = source.name;

    this.up.copy(source.up);

    this.position.copy(source.position);
    this.rotation.order = source.rotation.order;
    this.quaternion.copy(source.quaternion);
    this.scale.copy(source.scale);

    this.matrix.copy(source.matrix);
    this.matrixWorld.copy(source.matrixWorld);

    this.matrixAutoUpdate = source.matrixAutoUpdate;
    this.matrixWorldNeedsUpdate = source.matrixWorldNeedsUpdate;

    this.layers.mask = source.layers.mask;
    this.visible = source.visible;

    if (recursive === true) {
      for (let i = 0; i < source.children.length; i++) {
        const child = source.children[i];
        this.add(child.clone());
      }
    }

    return this;
  }
}

export class Scene extends Object3D {
  constructor() {
    super();

    Object.defineProperty(this, "isScene", { value: true });

    this.type = "Scene";
  }

  copy(source: Scene, recursive?: boolean) {
    super.copy(source, recursive);

    return this;
  }
}

const _start = new Vector3();
const _end = new Vector3();
const _inverseMatrix$1 = new Matrix4();
const _ray$1 = new Ray();
const _sphere$2 = new Sphere();

export class Line extends Object3D {
  isLine = true;

  geometry: BufferGeometry;
  material: LineBasicMaterial;

  constructor(
    geometry = new BufferGeometry(),
    material = new LineBasicMaterial()
  ) {
    super();

    this.type = "Line";

    this.geometry = geometry;
    this.material = material;
  }

  copy(source: Line) {
    Object3D.prototype.copy.call(this, source);

    this.material = source.material;
    this.geometry = source.geometry;

    return this;
  }

  computeLineDistances() {
    const geometry = this.geometry;

    if (geometry.isBufferGeometry) {
      // we assume non-indexed geometry

      if (geometry.index === null) {
        const positionAttribute = geometry.attributes.position;
        const lineDistances = [0];

        for (let i = 1, l = positionAttribute.count; i < l; i++) {
          _start.fromBufferAttribute(positionAttribute, i - 1);
          _end.fromBufferAttribute(positionAttribute, i);

          lineDistances[i] = lineDistances[i - 1];
          lineDistances[i] += _start.distanceTo(_end);
        }

        geometry.setAttribute(
          "lineDistance",
          new Float32BufferAttribute(lineDistances, 1)
        );
      } else {
        console.warn(
          "THREE.Line.computeLineDistances(): Computation only possible with non-indexed BufferGeometry."
        );
      }
    }

    return this;
  }

  raycast(raycaster, intersects) {
    const geometry = this.geometry;
    const matrixWorld = this.matrixWorld;
    const threshold = raycaster.params.Line.threshold;

    // Checking boundingSphere distance to ray
    if (geometry.boundingSphere === null) geometry.computeBoundingSphere();

    _sphere$2.copy(geometry.boundingSphere);
    _sphere$2.applyMatrix4(matrixWorld);
    _sphere$2.radius += threshold;

    if (raycaster.ray.intersectsSphere(_sphere$2) === false) return;

    _inverseMatrix$1.copy(matrixWorld).invert();
    _ray$1.copy(raycaster.ray).applyMatrix4(_inverseMatrix$1);

    const localThreshold =
      threshold / ((this.scale.x + this.scale.y + this.scale.z) / 3);
    const localThresholdSq = localThreshold * localThreshold;

    const vStart = new Vector3();
    const vEnd = new Vector3();
    const interSegment = new Vector3();
    const interRay = new Vector3();
    const step = 2; //It's always linesegments. this.isLineSegments ? 2 : 1;

    if (geometry.isBufferGeometry) {
      const index = geometry.index;
      const attributes = geometry.attributes;
      const positionAttribute = attributes.position;

      if (index !== null) {
        const indices = index.array;

        for (let i = 0, l = indices.length - 1; i < l; i += step) {
          const a = indices[i];
          const b = indices[i + 1];

          vStart.fromBufferAttribute(positionAttribute, a);
          vEnd.fromBufferAttribute(positionAttribute, b);

          const distSq = _ray$1.distanceSqToSegment(
            vStart,
            vEnd,
            interRay,
            interSegment
          );

          if (distSq > localThresholdSq) continue;

          interRay.applyMatrix4(this.matrixWorld); //Move back to world space for distance calculation

          const distance = raycaster.ray.origin.distanceTo(interRay);

          if (distance < raycaster.near || distance > raycaster.far) continue;

          intersects.push({
            distance: distance,
            // What do we want? intersection point on the ray or on the segment??
            // point: raycaster.ray.at( distance ),
            point: interSegment.clone().applyMatrix4(this.matrixWorld),
            index: i,
            face: null,
            faceIndex: null,
            object: this,
          });
        }
      } else {
        for (let i = 0, l = positionAttribute.count - 1; i < l; i += step) {
          vStart.fromBufferAttribute(positionAttribute, i);
          vEnd.fromBufferAttribute(positionAttribute, i + 1);

          const distSq = _ray$1.distanceSqToSegment(
            vStart,
            vEnd,
            interRay,
            interSegment
          );

          if (distSq > localThresholdSq) continue;

          interRay.applyMatrix4(this.matrixWorld); //Move back to world space for distance calculation

          const distance = raycaster.ray.origin.distanceTo(interRay);

          if (distance < raycaster.near || distance > raycaster.far) continue;

          intersects.push({
            distance: distance,
            // What do we want? intersection point on the ray or on the segment??
            // point: raycaster.ray.at( distance ),
            point: interSegment.clone().applyMatrix4(this.matrixWorld),
            index: i,
            face: null,
            faceIndex: null,
            object: this,
          });
        }
      }
    }
  }
}

const _start$1 = new Vector3();
const _end$1 = new Vector3();

export class LineSegments extends Line {
  isLineSegments = true;

  constructor(geometry, material) {
    super(geometry, material);

    this.type = "LineSegments";
  }
  computeLineDistances() {
    const geometry = this.geometry;

    if (geometry.isBufferGeometry) {
      // we assume non-indexed geometry

      if (geometry.index === null) {
        const positionAttribute = geometry.attributes.position;
        const lineDistances = [];

        for (let i = 0, l = positionAttribute.count; i < l; i += 2) {
          _start$1.fromBufferAttribute(positionAttribute, i);
          _end$1.fromBufferAttribute(positionAttribute, i + 1);

          lineDistances[i] = i === 0 ? 0 : lineDistances[i - 1];
          lineDistances[i + 1] = lineDistances[i] + _start$1.distanceTo(_end$1);
        }

        geometry.setAttribute(
          "lineDistance",
          new Float32BufferAttribute(lineDistances, 1)
        );
      } else {
        console.warn(
          "THREE.LineSegments.computeLineDistances(): Computation only possible with non-indexed BufferGeometry."
        );
      }
    }

    return this;
  }
}
