import * as MathUtils from "../utils/math";
import {
  NormalBlending,
  FrontSide,
  BasicDepthPacking,
  BackSide,
} from "./constants";
import { EventDispatcher } from "../events";
import { Color, Texture } from "./types";
import { Vector3 } from "../mathtypes";

let materialId = 0;

// WebGL Render Modes.
const RenderMode = {
  TRIANGLES: 4,
  LINES: 1,
};

export class Material extends EventDispatcher {
  isMaterial = true;
  type = "Material";

  fragShader = "";
  vertShader = "";

  renderMode = RenderMode.TRIANGLES;

  blending = NormalBlending;
  side = FrontSide;

  opacity = 1;
  transparent = false;

  depthTest = true;
  depthWrite = true;

  visible = true;

  version = 0;

  constructor() {
    super();

    this.id = materialId++;
    this.uuid = MathUtils.generateUUID();
    this.name = "";
  }

  /**
   * Set the shader uniforms to this material's values.
   * Needs to be called before rendering the material.
   * @param {WebGL2RenderingContext} gl
   * @param {Map<String, WebGLUniformLocation>} uniformLocations
   */
  updateUniforms(gl, uniformLocations) {}

  setValues(values) {
    if (values === undefined) return;

    for (const key in values) {
      const newValue = values[key];

      if (newValue === undefined) {
        console.warn("THREE.Material: '" + key + "' parameter is undefined.");
        continue;
      }

      const currentValue = this[key];

      if (currentValue === undefined) {
        console.warn(
          "THREE." +
            this.type +
            ": '" +
            key +
            "' is not a property of this material."
        );
        continue;
      }

      if (currentValue && currentValue.isColor) {
        currentValue.set(newValue);
      } else if (
        currentValue &&
        currentValue.isVector3 &&
        newValue &&
        newValue.isVector3
      ) {
        currentValue.copy(newValue);
      } else {
        this[key] = newValue;
      }
    }
  }

  dispose() {
    this.dispatchEvent({ type: "dispose" });
  }
}

Object.defineProperty(Material.prototype, "needsUpdate", {
  set: function (value) {
    if (value === true) this.version++;
  },
});

// Legacy
const default_vertex =
  "void main() {\n\tgl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n}";

const default_fragment =
  "void main() {\n  gl_FragColor = vec4( 1.0, 0.0, 0.0, 1.0 );\n}";

export class ShaderMaterial extends Material {
  isShaderMaterial = true;

  constructor() {
    super();

    this.type = "ShaderMaterial";

    this.vertexShader = default_vertex;
    this.fragmentShader = default_fragment;
  }
}

/**
 * parameters = {
 *  color: <hex>,
 *  opacity: <float>,
 *  map: new THREE.Texture( <Image> ),
 *
 *  lightMap: new THREE.Texture( <Image> ),
 *  lightMapIntensity: <float>
 *
 *  aoMap: new THREE.Texture( <Image> ),
 *  aoMapIntensity: <float>
 *
 *  specularMap: new THREE.Texture( <Image> ),
 *
 *  alphaMap: new THREE.Texture( <Image> ),
 *
 *  envMap: new THREE.CubeTexture( [posx, negx, posy, negy, posz, negz] ),
 *  combine: THREE.Multiply,
 *  reflectivity: <float>,
 *  refractionRatio: <float>,
 *
 *  depthTest: <bool>,
 *  depthWrite: <bool>,
 *
 *  wireframe: <boolean>,
 *  wireframeLinewidth: <float>,
 *
 * }
 */
export class MeshBasicMaterial extends Material {
  constructor(parameters) {
    super();

    this.type = "MeshBasicMaterial";
    this.isMeshBasicMaterial = true;

    this.fragShader = "mesh/basic.frag";
    this.vertShader = "mesh/basic.vert";

    this.color = new Color(0xffffff); // emissive

    this.setValues(parameters);
  }

  updateUniforms(gl, uniformLocations) {
    // Set the diffuse color, for most materials this applies a color tint to everthing on the geometry.
    gl.uniform4f(
      uniformLocations.get("diffuse"),
      this.color.r,
      this.color.g,
      this.color.b,
      this.opacity
    );
  }
}

/**
 * parameters = {
 *
 *  opacity: <float>,
 *
 *  map: new THREE.Texture( <Image> ),
 *
 *  alphaMap: new THREE.Texture( <Image> ),
 *
 *  displacementMap: new THREE.Texture( <Image> ),
 *  displacementScale: <float>,
 *  displacementBias: <float>,
 *
 *  wireframe: <boolean>,
 *  wireframeLinewidth: <float>
 * }
 */
export class MeshDepthMaterial extends Material {
  isMeshDepthMaterial = true;

  constructor(parameters) {
    super();

    this.type = "MeshDepthMaterial";

    this.depthPacking = BasicDepthPacking;

    this.map = null;

    this.alphaMap = null;

    this.displacementMap = null;
    this.displacementScale = 1;
    this.displacementBias = 0;

    this.wireframe = false;
    this.wireframeLinewidth = 1;

    this.setValues(parameters);
  }
}

/**
 * parameters = {
 *
 *  referencePosition: <float>,
 *  nearDistance: <float>,
 *  farDistance: <float>,
 *
 *
 *  map: new THREE.Texture( <Image> ),
 *
 *  alphaMap: new THREE.Texture( <Image> ),
 *
 *  displacementMap: new THREE.Texture( <Image> ),
 *  displacementScale: <float>,
 *  displacementBias: <float>
 *
 * }
 */
export class MeshDistanceMaterial extends Material {
  isMeshDistanceMaterial = true;

  constructor(parameters) {
    super();

    this.type = "MeshDistanceMaterial";

    this.referencePosition = new Vector3();
    this.nearDistance = 1;
    this.farDistance = 1000;

    this.map = null;

    this.alphaMap = null;

    this.displacementMap = null;
    this.displacementScale = 1;
    this.displacementBias = 0;

    this.setValues(parameters);
  }
}

/**
 * parameters = {
 *  map: new THREE.Texture( <Image> ),
 *  rotation: <float>,
 *  sizeAttenuation: <bool>
 * }
 */
export class SpriteMaterial extends Material {
  /**
   * The texture applied to this sprite
   * @type {Texture}
   */
  map = null;

  constructor(parameters) {
    super();

    this.type = "SpriteMaterial";
    this.isSpriteMaterial = true;

    this.map = null;

    this.fragShader = "mesh/basic_textured.frag";
    this.vertShader = "mesh/basic_billboard.vert";

    this.rotation = 0;

    this.transparent = true;

    this.setValues(parameters);
  }
  /**
   * Set the shader uniforms to this material's values.
   * Needs to be called before rendering the material.
   * @param {WebGL2RenderingContext} gl
   * @param {Map<String, WebGLUniformLocation>} uniformLocations
   */
  updateUniforms(gl, uniformLocations) {
    if (this.map?.needsUpdate) {
      if (!this.map.textureGlId) {
        this.map.textureGlId = gl.createTexture();
      }

      // Canvases render with (0, 0) at the top-left, WebGL textures have (0,0) at the bottom left.
      // So we need to tell the GPU that this is flipped upside down.
      gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
      // Set the texture we are operating on.
      gl.bindTexture(gl.TEXTURE_2D, this.map.textureGlId);
      // Upload image data to the GPU.
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        this.map.image
      );

      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

      this.map.needsUpdate = false;
    }

    if (this.map.textureGlId) {
      gl.activeTexture(gl.TEXTURE0);
      gl.bindTexture(gl.TEXTURE_2D, this.map.textureGlId);
      // Set the sampler to use this image.
      gl.uniform1i(uniformLocations.get("textureMap"), 0);
    }
  }
}

/**
 * parameters = {
 *  color: <hex>,
 *
 * }
 */
export class LineBasicMaterial extends Material {
  isLineBasicMaterial = true;

  constructor(parameters) {
    super();

    this.renderMode = RenderMode.LINES;

    this.vertShader = "mesh/line.vert";
    this.fragShader = "flat_mesh.frag";

    this.type = "LineBasicMaterial";

    this.color = new Color(0xffffff);

    this.setValues(parameters);
  }

  updateUniforms(gl, uniformLocations) {
    // Set the diffuse color, for most materials this applies a color tint to everthing on the geometry.
    gl.uniform3f(
      uniformLocations.get("diffuse"),
      this.color.r,
      this.color.g,
      this.color.b
    );
  }
}

/**
 * parameters = {
 *  color: <hex>,
 *  opacity: <float>,
 *  map: new THREE.Texture( <Image> ),
 *  alphaMap: new THREE.Texture( <Image> ),
 *
 *  size: <float>,
 *  sizeAttenuation: <bool>
 *
 * }
 */
export class PointsMaterial extends Material {
  isPointsMaterial = true;

  constructor(parameters) {
    super();

    this.type = "PointsMaterial";

    this.color = new Color(0xffffff);

    this.map = null;

    this.alphaMap = null;

    this.size = 1;
    this.sizeAttenuation = true;

    this.setValues(parameters);
  }
  copy(source) {
    Material.prototype.copy.call(this, source);

    this.color.copy(source.color);

    this.map = source.map;

    this.alphaMap = source.alphaMap;

    this.size = source.size;
    this.sizeAttenuation = source.sizeAttenuation;

    return this;
  }
}

/**
 * Renders the Mesh's normals as color.
 */
export class MeshNormalMaterial extends Material {
  isMeshNormalMaterial = true;

  constructor() {
    super();

    this.type = "MeshNormalMaterial";

    this.fragShader = "mesh/normal.frag";
    this.vertShader = "mesh/basic.vert";
  }
}

export class SkyboxMaterial extends Material {
  imageUrls = [];
  #textureId = null;

  loadedFaces = 0;

  constructor(imageUrls) {
    super();

    this.imageUrls = imageUrls;
    this.side = BackSide;

    this.fragShader = "mesh/skybox.frag";
    this.vertShader = "mesh/skybox.vert";

    this.depthTest = false;
    this.depthWrite = false;
  }

  /**
   * Set the shader uniforms to this material's values.
   * Needs to be called before rendering the material.
   * @param {WebGL2RenderingContext} gl
   * @param {Map<String, WebGLUniformLocation>} uniformLocations
   */
  updateUniforms(gl, uniformLocations) {
    if (this.#textureId === null) {
      console.info("Creating skybox");
      this.#textureId = gl.createTexture();
      // Load the six faces of the cube map
      const faceInfos = [
        { target: gl.TEXTURE_CUBE_MAP_POSITIVE_X, url: this.imageUrls[0] },
        { target: gl.TEXTURE_CUBE_MAP_NEGATIVE_X, url: this.imageUrls[1] },
        { target: gl.TEXTURE_CUBE_MAP_POSITIVE_Y, url: this.imageUrls[2] },
        { target: gl.TEXTURE_CUBE_MAP_NEGATIVE_Y, url: this.imageUrls[3] },
        { target: gl.TEXTURE_CUBE_MAP_POSITIVE_Z, url: this.imageUrls[4] },
        { target: gl.TEXTURE_CUBE_MAP_NEGATIVE_Z, url: this.imageUrls[5] },
      ];

      faceInfos.forEach((faceInfo) => {
        const { target, url } = faceInfo;

        const image = new Image();
        image.src = url;
        image.onload = () => {
          // Upload the image into the cube map face
          gl.bindTexture(gl.TEXTURE_CUBE_MAP, this.#textureId);
          gl.texImage2D(target, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

          // Set the texture filtering (important for rendering the skybox)
          gl.texParameteri(
            gl.TEXTURE_CUBE_MAP,
            gl.TEXTURE_MIN_FILTER,
            gl.LINEAR
          );
          gl.texParameteri(
            gl.TEXTURE_CUBE_MAP,
            gl.TEXTURE_MAG_FILTER,
            gl.LINEAR
          );
          gl.texParameteri(
            gl.TEXTURE_CUBE_MAP,
            gl.TEXTURE_WRAP_S,
            gl.CLAMP_TO_EDGE
          );
          gl.texParameteri(
            gl.TEXTURE_CUBE_MAP,
            gl.TEXTURE_WRAP_T,
            gl.CLAMP_TO_EDGE
          );
          gl.texParameteri(
            gl.TEXTURE_CUBE_MAP,
            gl.TEXTURE_WRAP_R,
            gl.CLAMP_TO_EDGE
          );

          this.loadedFaces += 1;
        };
      });
    }

    if (this.loadedFaces == 6) {
      gl.activeTexture(gl.TEXTURE0);
      gl.bindTexture(gl.TEXTURE_CUBE_MAP, this.#textureId);
      gl.uniform1i(uniformLocations.get("skybox"), 0);
    }
  }
}

export class RawShaderMaterial extends ShaderMaterial {
  isRawShaderMaterial = true;

  constructor() {
    super();

    this.type = "RawShaderMaterial";
  }
}

//
// Algorithm by Christian Boucheny
// shader code taken and adapted from CloudCompare
//
// see
// https://github.com/cloudcompare/trunk/tree/master/plugins/qEDL/shaders/EDL
// http://www.kitware.com/source/home/post/9
// https://tel.archives-ouvertes.fr/tel-00438464/document p. 115+ (french)

export class EyeDomeLightingMaterial extends RawShaderMaterial {
  constructor() {
    super();

    this.type = "EyeDomeLightingMaterial";

    this.depthWrite = true;
    this.depthTest = true;
    this.transparent = true;

    this.transparent = true;

    this.vertShader = "edl.vert";
    this.fragShader = "edl.frag";

    let uniforms = {
      screenWidth: { type: "f", value: 0 },
      screenHeight: { type: "f", value: 0 },
      edlStrength: { type: "f", value: 1.0 },
      uNear: { type: "f", value: 1.0 },
      uFar: { type: "f", value: 1.0 },
      radius: { type: "f", value: 1.0 },
      neighbours: { type: "2fv", value: [] },
      depthMap: { type: "t", value: null },
      uEDLColor: { type: "t", value: null },
      uEDLDepth: { type: "t", value: null },
      opacity: { type: "f", value: 1.0 },
      uProj: { type: "Matrix4fv", value: [] },
    };

    this.uniforms = uniforms;

    /**Set the neighbourCount & generate UV offsets for them.
     * If this is changed it also needs to be changed in the `edl.frag` shader.
     */
    this.neighbourCount = 8;
  }

  /**
   * Set the shader uniforms to this material's values.
   * Needs to be called before rendering the material.
   * @param {WebGL2RenderingContext} gl
   * @param {Map<String, WebGLUniformLocation>} uniformLocations
   */
  updateUniforms(gl, uniformLocations) {
    gl.uniform1f(
      uniformLocations.get("screenWidth"),
      this.uniforms.screenWidth.value
    );
    gl.uniform1f(
      uniformLocations.get("screenHeight"),
      this.uniforms.screenHeight.value
    );

    gl.uniform1f(
      uniformLocations.get("edlStrength"),
      this.uniforms.edlStrength.value
    );
    gl.uniform1f(uniformLocations.get("radius"), this.uniforms.radius.value);
    gl.uniform1f(uniformLocations.get("opacity"), this.uniforms.opacity.value);

    gl.uniform2fv(uniformLocations.get("neighbours[0]"), this.neighbours);

    gl.uniformMatrix4fv(
      uniformLocations.get("uProj"),
      false,
      this.uniforms.uProj.value
    );

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, this.uniforms.uEDLColor.value);
    gl.uniform1i(uniformLocations.get("uEDLColor"), 0);
  }

  get neighbourCount() {
    return this._neighbourCount;
  }

  set neighbourCount(value) {
    if (this._neighbourCount !== value) {
      this._neighbourCount = value;
      this.neighbours = new Float32Array(this._neighbourCount * 2);
      for (let c = 0; c < this._neighbourCount; c++) {
        this.neighbours[2 * c + 0] = Math.cos(
          (2 * c * Math.PI) / this._neighbourCount
        );
        this.neighbours[2 * c + 1] = Math.sin(
          (2 * c * Math.PI) / this._neighbourCount
        );
      }
    }
  }
}
