// @ts-check

import { addLineNumbers } from "potree/utils/string";
import * as Shaders from "../rendering/shaders";

export class SQCache {
  /**
   *  Cache of compiled shader programs.
   */
  #shaderCache = new Map();

  #uniformCache = new Map();

  /**
   * @param vert Vertex shader path
   * @param frag Vertex shader path
   */
  getProgram(gl: WebGL2RenderingContext, vert: string, frag: string) {
    const key = `${vert} ${frag}`;

    const existingProgram = this.#shaderCache.get(key);
    if (existingProgram) {
      return existingProgram;
    }

    const program = this.#createProgram(gl, vert, frag);

    this.#shaderCache.set(key, program);

    return program;
  }

  /**
   * Returns a shader program with key `name`.
   *
   * Exists as an alternative with a "cheaper" hash key to `getProgram`.
   *
   * @param name Unique key
   * @param vert Vertex shader path
   * @param frag Vertex shader path
   */
  getProgramNamed(
    gl: WebGL2RenderingContext,
    name: String,
    vert: String,
    frag: String,
  ): { program: WebGLProgram; uniforms: Map<string, WebGLUniformLocation> } {
    const existingProgram = this.#shaderCache.get(name);
    if (existingProgram) {
      return {
        program: existingProgram,
        uniforms: this.#uniformCache.get(name),
      };
    }

    const program = this.#createProgram(gl, vert, frag);
    const uniforms = this.#extractUniforms(gl, program);

    this.#shaderCache.set(name, program);
    this.#uniformCache.set(name, uniforms);

    return {
      program,
      uniforms,
    };
  }

  /**
   * Returns all the uniforms & their location in the shader program.
   * This includes uniforms from both the vertex & fragment shader.
   */
  #extractUniforms(
    gl: WebGL2RenderingContext,
    program: WebGLProgram,
  ): Map<string, WebGLUniformLocation> {
    const uniformCount = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);

    const map = new Map();

    for (let i = 0; i < uniformCount; ++i) {
      const info = gl.getActiveUniform(program, i),
        addr = gl.getUniformLocation(program, info.name);

      map.set(info.name, addr);
    }

    return map;
  }

  /**
   * Compile a program with the provided vertex & fragment shaders.
   *
   * @param vert Vertex shader path
   * @param frag Vertex shader path
   */
  #createProgram(
    gl: WebGL2RenderingContext,
    vert: String,
    frag: String,
  ): WebGLProgram {
    const program = gl.createProgram();

    const vertexShaderString = Shaders.get(vert);
    if (!vertexShaderString) {
      console.error(`No Vertex Shader found for: ${vert}`);
    }
    const fragmentShaderString = Shaders.get(frag);
    if (!fragmentShaderString) {
      console.error(`No Fragment Shader found for: ${frag}`);
    }

    const vertexShader = this.#createShader(
      gl,
      gl.VERTEX_SHADER,
      vertexShaderString,
    );
    const fragmentShader = this.#createShader(
      gl,
      gl.FRAGMENT_SHADER,
      fragmentShaderString,
    );

    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);

    gl.linkProgram(program);

    checkDiagnostics(gl, program, vertexShader, fragmentShader, vert, frag);

    // Clean up
    gl.deleteShader(vertexShader);
    gl.deleteShader(fragmentShader);
    return program;
  }

  /**
   * @param type Shader type as a WebGL constant
   * @param shader_source Source code for the shader
   */
  #createShader(
    gl: WebGL2RenderingContext,
    type: number,
    shader_source: string,
  ): WebGLShader {
    const shader = gl.createShader(type);

    gl.shaderSource(shader, shader_source);
    gl.compileShader(shader);

    return shader;
  }
}

/**
 * Checks for errors in compiling the shader program.
 *
 * @param vertex_name Vertex shader name for debugging
 * @param fragment_name Fragment shader name for debugging
 */
function checkDiagnostics(
  gl: WebGL2RenderingContext,
  program: WebGLProgram,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader,
  vertex_name: String,
  fragment_name: String,
) {
  const programLog = gl.getProgramInfoLog(program).trim();

  gl.validateProgram(program);
  if (!gl.getProgramParameter(program, gl.VALIDATE_STATUS)) {
    console.error(gl.getProgramInfoLog(program));
  }

  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    const vertexErrors = getShaderErrors(gl, vertexShader, "vertex");
    const fragmentErrors = getShaderErrors(gl, fragmentShader, "fragment");

    /**
     * All of our shaders are prewritten & no code is generated after publishing.
     * This is thus mostly useful for debugging locally. Any error here happens loudly during development.
     *
     * If this triggers in prod someone committed bad shaders.
     */
    console.error(
      "Shader error: ",
      gl.getError(),
      "\n[35715]:\n",
      gl.getProgramParameter(program, 35715),
      "\n[Log]:\n",
      programLog,
      "\n[Vertex Errors]:\n",
      vertexErrors,
      "\n[Fragment Errors]:\n",
      fragmentErrors,
    );
  } else if (programLog !== "") {
    // Warnings for the shader. Much like when writing any other code, these should be fixed but can be ignored.
    console.warn(
      `gl.getProgramInfoLog() (${vertex_name}, ${fragment_name}):`,
      programLog,
    );
  }
}

/**
 * @param shader_type Shader type as a string for debugging
 */
function getShaderErrors(
  gl: WebGL2RenderingContext,
  shader: WebGLShader,
  shader_type: "vertex" | "fragment",
) {
  const status = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  const log = gl.getShaderInfoLog(shader)?.trim() || "";

  if (status && !log) return "";

  // --enable-privileged-webgl-extension
  const source = gl.getShaderSource(shader);

  return `
      THREE.WebGLShader: gl.getShaderInfoLog() ${shader_type}
      ${log}
      ${addLineNumbers(source)}
  `;
}
